.nav-section {
	position: -webkit-sticky;
	position: static;
	z-index: 2000;
	display: block;
	height: auto;
	padding-top: 0px;
	padding-right: 60px;
	padding-left: 60px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
	box-shadow: 1px 13px 18px -13px rgba(0, 0, 0, 0.55);
}

.navbar {
	display: block;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding: 9px 0px;
	background-color: transparent;
	color: #196ab5;
}

.brand {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 180px;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	display: inline-block;
}

.nav-menu {
	position: relative;
	left: auto;
	top: 56px;
	right: 0%;
	bottom: 0%;
	z-index: 1200;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	min-height: 100%;
	padding-right: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.main-img {
	position: -webkit-sticky;
	position: sticky;
	top: 127px;
	z-index: -1;
	height: auto;
	margin-top: 0px;
	margin-bottom: 0px;
}

.slider {
	height: 650px;
}

.img-slides {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.left-arrow {
	left: 0%;
	top: auto;
	right: auto;
	bottom: 0%;
	display: none;
	height: 31px;
	margin-bottom: 10px;
}

.right-arrow {
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	display: none;
	height: 31px;
	margin-bottom: 10px;
}

.slide-nav {
	display: none;
	color: #196ab5;
}

.dot-icon {
	color: #333;
	font-size: 30px;
}

.icon-2 {
	color: #333;
	font-size: 30px;
}

.callout {
	position: relative;
	z-index: 10000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-top: -195px;
	padding: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 1px;
	border-color: #ca4949;
	background-color: transparent;
}

.nav-link {
	padding-top: 20px;
	padding-bottom: 5px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.nav-link:hover {
	color: #ca4949;
}

.nav-link.w--current {
	color: #ca4949;
}

.welcome-to {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 110px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 50px;
	font-weight: 700;
}

.image-4 {
	width: 100%;
	max-width: 55%;
}

.slide-2 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.wave-1 {
	position: relative;
	left: 0%;
	top: 3%;
	right: 0%;
	bottom: auto;
	z-index: 1100;
	width: 100%;
	max-width: none;
}

.side-icons {
	position: fixed;
	left: 0%;
	top: 32%;
	right: auto;
	bottom: auto;
	z-index: 3000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-left: 17px;
	padding-top: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dropdown-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dropdown {
	position: absolute;
	left: auto;
	top: 62px;
	right: auto;
	bottom: auto;
	z-index: 1000;
	display: block;
	width: 200px;
	height: 0%;
	min-width: auto;
	padding: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: #196ab5;
	opacity: 0;
}

.drop-down-link {
	position: relative;
	width: 100%;
	padding: 10px 20px;
	border-radius: 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	line-height: 19px;
	font-weight: 400;
	text-decoration: none;
}

.drop-down-link:hover {
	border-radius: 0px;
	background-color: hsla(0, 0%, 100%, 0.14);
	color: #fff;
	font-weight: 400;
}

.drop-down-link.w--current {
	background-color: hsla(0, 0%, 100%, 0.16);
}

.paybill {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70px;
	height: 70px;
	margin-top: 10px;
	margin-bottom: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 100%;
	background-color: #ca4949;
	background-image: url('/20181016082423/assets/images/pay-bill.svg');
	background-position: 50% 50%;
	background-size: 50px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.paybill:hover {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
}

.sideicon-text {
	opacity: 0;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}

.pay-bill {
	width: 80px;
	height: 80px;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-transition-property: none;
	transition-property: none;
}

.side-link {
	width: 80px;
	height: 80px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-decoration: none;
}

.calendar {
	width: 80px;
	height: 80px;
	margin-top: 0px;
	margin-bottom: 0px;
	background-image: url('/20181016082423/assets/images/calendar.svg');
	background-size: 40px;
	-webkit-transition-property: none;
	transition-property: none;
}

.sideicon-text-3 {
	opacity: 0;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}

.minuets-icon {
	width: 80px;
	height: 80px;
	margin-top: 0px;
	margin-bottom: 0px;
	background-image: url('/20181016082423/assets/images/clock.svg');
	-webkit-transition-property: none;
	transition-property: none;
}

.sideicon-text-2 {
	opacity: 0;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	text-align: center;
}

.left-alined-title {
	max-width: 1500px;
	margin: 0px auto 40px;
	font-family: 'Amatic SC', sans-serif;
	color: #fff;
	font-size: 67px;
	line-height: 65px;
}

.main-paragraph {
	max-width: 800px;
	margin-top: 30px;
	margin-bottom: 30px;
	padding-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 15px;
	line-height: 26px;
	text-align: center;
	width: 100%;
}

.content-button {
	padding: 10px 20px;
	border-style: none;
	border-width: 1px;
	border-color: rgba(210, 210, 210, 0.81);
	border-radius: 0px;
	background-color: #ca4949;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Amatic SC', sans-serif;
	color: #fff;
	font-size: 26px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: 2px;
}

.content-button:hover {
	border-color: rgba(25, 106, 181, 0.55);
	background-color: #196ab5;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.content-boxes {
	position: relative;
	z-index: 10000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 80px 0%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-attachment: scroll, fixed;
}

.cb-content {
	width: 100%;
	max-width: none;
	margin: 0px 0%;
	padding-right: 10%;
	padding-left: 10%;
}

.wave-2 {
	position: relative;
	z-index: 0;
	width: 100%;
	height: auto;
}

.cb-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 32%;
	height: auto;
	padding: 0px 0px 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 7px 7px 18px -4px rgba(0, 0, 0, 0.44);
}

.centered-title {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: 'Amatic SC', sans-serif;
	color: #196ab5;
	font-size: 67px;
	line-height: 65px;
	letter-spacing: 2px;
}

.cb-title {
	margin: 0px 40px 5px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 30px;
	line-height: 35px;
	font-weight: 600;
	width: calc(100% - 80px);
}

.paragraph-2 {
	margin-right: 40px;
	margin-bottom: 20px;
	margin-left: 40px;
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	line-height: 26px;
	width: calc(100% - 80px);
}

.events-fb {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 80px 10%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
	z-index: 10000;
}

.div-block-3 {
	opacity: 0;
}

.div-block-4 {
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	opacity: 1;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.news {
	width: 60%;
	background-color: transparent;
	color: #196ab5;
}

.fb-feed {
	width: 35%;
}

.alert-section {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0px;
	z-index: 3000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 65px;
	padding-right: 10%;
	padding-left: 10%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ca4949;
	color: #ca4949;
}

.alert-text {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
}

.close-alert {
	position: absolute;
	left: auto;
	top: 20%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40px;
	height: 40px;
	margin-right: 40px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('/20181016082423/assets/images/x.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	font-size: 11px;
	cursor: pointer;
}

.news-title {
	margin-top: 0px;
	margin-bottom: 20px;
	font-family: 'Amatic SC', sans-serif;
	color: #196ab5;
	font-size: 67px;
	line-height: 65px;
	letter-spacing: 2px;
}

.image-7 {
	margin-top: 20px;
}

.contact-form {
	position: relative;
	z-index: 100000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-bottom: -2px;
	padding-top: 60px;
	padding-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #196ab5;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(25, 106, 181, 0.41)), to(rgba(25, 106, 181, 0.41))), var(--wave-bg);
	background-image: linear-gradient(180deg, rgba(25, 106, 181, 0.41), rgba(25, 106, 181, 0.41)), var(--wave-bg);
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.form-block {
	width: 45%;
}

.form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-family: 'Open Sans', sans-serif;
}

.field-label {
	color: #fff;
	font-size: 21px;
	line-height: 26px;
	font-weight: 600;
}

.text-field {
	margin-bottom: 20px;
}

.field-label-2 {
	color: #fff;
	font-size: 21px;
	line-height: 26px;
	font-weight: 600;
}

.text-field-2 {
	margin-bottom: 20px;
}

.comment-field {
	height: 100px;
	margin-bottom: 20px;
}

.centered-title-white {
	margin-top: 0px;
	margin-bottom: 40px;
	font-family: 'Amatic SC', sans-serif;
	color: #fff;
	font-size: 67px;
	line-height: 65px;
}

.footer-section {
	position: relative;
	z-index: 10000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding: 20px 10%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
}

.footer-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-heading {
	font-family: 'Amatic SC', sans-serif;
	color: #196ab5;
	font-size: 40px;
	line-height: 45px;
}

.footer-text {
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 16px;
	line-height: 24px;
}

.footer-link {
	margin-bottom: 5px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 16px;
	line-height: 23px;
	display: inline-block;
	font-weight: 600;
	text-decoration: none;
}

.footer-link:hover {
	opacity: 1;
	color: #ca4949;
}

.footer-link.w--current {
	margin-bottom: 5px;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-decoration: none;
}

.callout-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	max-width: none;
	margin: -3px auto -2px;
	padding: 60px 60px 80px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.cool-button {
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(202, 73, 73, 0.32);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.nav-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-underline {
	width: 0px;
	height: 2px;
	margin-bottom: 15px;
	background-color: #ca4949;
	opacity: 0;
	color: #ca4949;
}

.nav-underline-2 {
	width: 0px;
	height: 2px;
	margin-bottom: 15px;
	background-color: #ca4949;
	opacity: 0;
	color: #ca4949;
}

.nav-underline-3 {
	width: 0px;
	height: 2px;
	margin-bottom: 15px;
	background-color: #ca4949;
	opacity: 0;
	color: #ca4949;
}

.nav-underline-4 {
	width: 0px;
	height: 2px;
	margin-bottom: 15px;
	background-color: #ca4949;
	opacity: 0;
	color: #ca4949;
}

.nav-underline-5 {
	width: 0px;
	height: 2px;
	margin-bottom: 15px;
	background-color: #ca4949;
	opacity: 0;
	color: #ca4949;
}

.cb-button {
	margin-left: 40px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(202, 73, 73, 0.32);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.paragraph-copy {
	max-width: 800px;
	margin-top: 10px;
	margin-bottom: 30px;
	padding-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 15px;
	line-height: 26px;
}

.sign-off {
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
}

.footer-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-bottom: 20px;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.form-buttons {
	margin-right: auto;
	margin-left: auto;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: none;
	border-width: 0.5px;
	border-color: #fff;
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #333;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	opacity: 1;
	color: #196ab5;
	font-weight: 400;
}

.footer-signoff-link:active {
	border: 1px none #000;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid transparent;
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border: 1px none #000;
}

.footer-signoff-links.last:hover {
	border: 1px none #000;
	color: #196ab5;
	font-weight: 400;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #333;
	font-size: 0.9rem;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-dkgray {
	color: #333;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.columns {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	padding-top: 20px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(25, 106, 181, 0.22);
	font-family: 'Open Sans', sans-serif;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.dkgray {
	width: 136px;
	background-size: 130px 29px;
}

.footer-grip-link.white-png {
	background-size: 130px;
}

.link {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #196ab5;
	font-weight: 600;
	text-decoration: none;
}

.link:hover {
	opacity: 0.53;
	color: #ca4949;
}

.div-block-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.image-12 {
	position: relative;
	width: 100%;
	margin-bottom: -3px;
	background-color: transparent;
}

.search-3 {
	position: relative;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1300;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	color: #fff;
}

.search-button-3 {
	width: 40px;
	height: 40px;
	margin-left: 10px;
	border-radius: 100%;
	background-image: url('/20181016082423/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
}

.search-input-3 {
	position: absolute;
	top: 10px;
	z-index: auto;
	width: 300px;
	height: 0px;
	border-style: solid;
	border-width: 23px;
	border-color: #196ab5;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #fff;
	opacity: 0;
	right: -50px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
}

.search-button-4 {
	position: static;
	top: -1px;
	margin-bottom: 14px;
	background-color: #196ab5;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-button-4:hover {
	background-color: #ca4949;
}

.mobile-nav-link {
	display: none;
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Amatic SC', sans-serif;
	color: #196ab5;
	font-size: 27px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: 0px;
}

.mobile-nav-link:hover {
	color: #ca4949;
}

.cb-img-1 {
	width: 100%;
	height: 250px;
	margin-bottom: 20px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-img-2 {
	width: 100%;
	height: 250px;
	margin-bottom: 20px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-img-3 {
	width: 100%;
	height: 250px;
	margin-bottom: 20px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.mobile-icon-links {
	display: none;
}

.breadcrumbs-list {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
}

.block-quote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-color: #196ab5;
	border-top-right-radius: 0px;
	background-color: hsla(0, 0%, 72.9%, 0.18);
	color: #333;
	font-size: 1.35rem;
	line-height: 2rem;
}

.heading-7 {
	text-transform: none;
}

.breadcrumbs-wrapper {
	display: block;
	max-width: 1500px;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.heading-link {
	border-bottom: 1px none #fce32e;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #196ab5;
	line-height: 18px;
	text-decoration: underline;
	cursor: pointer;
}

.heading-link:hover {
	border-bottom-color: transparent;
	color: #ca4949;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: transparent;
}

.heading-4 {
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-weight: 600;
	text-transform: none;
}

.left-nav-col {
	overflow: hidden;
	width: 23%;
	height: 100%;
	max-width: 250px;
	min-height: 300px;
	min-width: 200px;
	margin-right: 1vw;
	padding: 0rem 0rem 4rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 1px none rgba(0, 0, 0, 0.15);
	border-bottom: 1px none #000;
	border-radius: 10px;
	background-color: transparent;
	color: #333;
}

.ordered-list {
	padding-left: 20px;
}

.heading-2 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 50px;
	line-height: 55px;
	font-weight: 600;
	text-transform: none;
}

.inside-content {
	position: relative;
	z-index: 0;
	max-width: none;
	margin: 60px 10% -237px;
	padding: 1rem 1rem 11.6rem;
	background-color: #fff;
	padding-bottom: 280px;
}

.image-2 {
	width: 50%;
	margin-bottom: 10px;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(25, 106, 181, 0.55);
}

.left-nav-wrapper {
	overflow: hidden;
	margin-right: 0rem;
	margin-left: 0rem;
	padding-bottom: 0rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(25, 106, 181, 0.19);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff)), var(--wave-bg);
	background-image: linear-gradient(180deg, #fff, #fff), var(--wave-bg);
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.left-nav-section-title {
	margin-top: 0px;
	margin-bottom: 0px;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
	line-height: 50px;
	text-transform: uppercase;
}

.text-link {
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #196ab5;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #ca4949;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
	color: #333;
}

.button-2 {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #fce32e;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2d2e31;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.button-2:hover {
	background-color: #333;
	color: #fce32e;
}

.button-2.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 10px 20px;
	border: 1px solid #c4ccd1;
	border-radius: 0px;
	background-color: transparent;
	color: #585b5d;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-transform: none;
}

.button-2.tertiary:hover {
	background-color: #f1f1f1;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

.table {
	color: #333;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #196ab5;
	font-size: 1rem;
}

.breadcrumb-link {
	border-bottom: 1px none #196ab5;
	opacity: 0.69;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	opacity: 1;
	color: #333;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	opacity: 1;
	color: #196ab5;
	font-weight: 700;
}

.breadcrumb-link.current-bc-link:hover {
	color: #196ab5;
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	padding: 0.75rem 0px 1rem 1rem;
	border-style: none;
	border-width: 2px;
	border-color: #196ab5;
	background-color: #196ab5;
	font-family: 'Amatic SC', sans-serif;
	color: #fff;
	font-size: 2.4rem;
	line-height: 2.4rem;
	font-weight: 700;
	text-decoration: none;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #333;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.main-content-inner-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1500px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	border-radius: 10px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.left-nav-link-3 {
	display: block;
	width: 100%;
	margin-bottom: 0rem;
	padding: 15px 0rem 15px 0.5rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 rgba(25, 106, 181, 0.19) transparent;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #333;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-3:hover {
	padding-right: 0rem;
	padding-left: 0.5rem;
	border-left: 7px solid transparent;
	color: #ca4949;
}

.left-nav-link-3.w--current {
	padding: 15px 0.5rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 rgba(25, 106, 181, 0.19);
	border-radius: 0px;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #196ab5;
	text-transform: none;
}

.left-nav-link-3.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-3.grandchild-link {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 2rem;
	border-left-color: transparent;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	border-left-color: transparent;
}

.left-nav-link-3.grandchild-link.w--current {
	padding-top: 15px;
	padding-bottom: 15px;
	border-left-color: #ca4949;
	background-image: none;
	font-weight: 600;
}

.left-nav-link-3.greatgrandchild-link {
	width: auto;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 3rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 rgba(25, 106, 181, 0.19) transparent;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	border-left: 8px solid transparent;
	color: #ca4949;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	margin-right: 0rem;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 3rem;
	border-left: 3px none #ca4949;
	border-bottom-style: solid;
	border-bottom-color: rgba(25, 106, 181, 0.19);
	background-image: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #196ab5;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 400;
}

.body {
	background-color: #f2f4f6;
}

.paragraph-3 {
	color: #333;
	font-size: 16px;
	line-height: 23px;
}

.primary-button {
	margin-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(202, 73, 73, 0.32);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.primary-button:hover {
	background-color: #ca4949;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.secondary-button {
	margin-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(25, 106, 181, 0.55);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.section-3 {
	background-color: #196ab5;
}

.footer-heading-inside {
	font-family: 'Amatic SC', sans-serif;
	color: #fff;
	font-size: 40px;
	line-height: 45px;
}

.footer-text-inside {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
}

.email-link-inside {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-weight: 600;
	text-decoration: none;
}

.email-link-inside:hover {
	opacity: 0.53;
	color: #fff;
}

.footer-link-inside {
	margin-bottom: 5px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-decoration: none;
}

.footer-link-inside:hover {
	opacity: 0.53;
	color: #fff;
}

.footer-link-inside.w--current {
	margin-bottom: 5px;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-decoration: none;
}

.footer-copyright-inside {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 0.9rem;
}

.footer-signoff-link-inside {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link-inside:hover {
	opacity: 0.53;
	color: #fff;
	font-weight: 400;
}

.footer-signoff-link-inside:active {
	border: 1px none #000;
}

.heading-8 {
	color: #196ab5;
}

.heading-9 {
	color: #196ab5;
}

.heading-10 {
	color: #196ab5;
}

.image-13 {
	overflow: hidden;
	width: 100%;
	max-width: 100%;
	margin-bottom: -3px;
	padding-right: 1.4px;
	padding-left: 1.3px;
	border-top-left-radius: 200%;
	border-top-right-radius: 200%;
}

.child-wrap {
	padding-bottom: 0px;
	padding-left: 0rem;
	border-bottom: 1px none #000;
}

.right-nav {
	width: auto;
	max-width: 21%;
	padding-left: 0rem;
	font-style: normal;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	color: #196ab5;
	font-style: normal;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.link-3 {
	border-style: solid solid none;
	border-width: 1px;
	border-color: transparent;
	color: #196ab5;
	font-size: 1rem;
	text-decoration: none;
}

.link-3:hover {
	border-bottom: 0px none rgba(156, 203, 60, 0);
	color: #ca4949;
}

.link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

@media screen and (max-width: 991px) {
	.nav-section {
		height: auto;
		padding-right: 5%;
		padding-left: 5%;
	}

	.navbar {
		position: static;
	}

	.brand {
		width: 150px;
	}

	.nav-menu {
		position: absolute;
		left: auto;
		top: 0%;
		right: 0%;
		bottom: 0%;
		width: 300px;
		margin-top: 110px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		background-color: #196ab5;
	}

	.main-img {
		top: 0;
	}

	.slider {
		height: 500px;
	}

	.nav-link {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		color: #fff;
	}

	.side-icons {
		left: 1%;
		display: none;
		margin-left: 0px;
		padding-top: 0px;
		padding-bottom: 208px;
		padding-left: 0px;
	}

	.paybill {
		width: 70px;
		height: 70px;
	}

	.side-link {
		width: 70px;
		height: 70px;
	}

	.icon-3 {
		font-size: 42px;
	}

	.cb-content {
		padding-right: 12%;
		padding-left: 12%;
	}

	.cb-container {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.events-fb {
		padding-right: 12%;
		padding-left: 12%;
	}

	.news {
		width: 1000%;
	}

	.fb-feed {
		width: 100%;
	}

	.alert-section {
		height: 70px;
		text-align: center;
	}

	.alert-text {
		max-width: 500px;
		font-size: 20px;
		line-height: 25px;
	}

	.image-7 {
		margin-top: 0px;
	}

	.form-block {
		width: 70%;
	}

	.footer-section {
		padding-right: 12%;
		padding-left: 12%;
	}

	.footer-column {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-text {
		text-align: center;
	}

	.callout-container {
		margin-top: -3px;
		padding-top: 0px;
		padding-right: 12%;
		padding-left: 12%;
	}

	.cool-button {
		font-size: 12px;
		line-height: 17px;
	}

	.nav-link-wrapper {
		display: none;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.cb-button {
		font-size: 12px;
		line-height: 17px;
	}

	.footer-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.form-buttons {
		font-size: 12px;
		line-height: 17px;
	}

	.column-2 {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.column-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 0rem;
		float: left;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.columns {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.div-block-6 {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-3 {
		margin-top: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.search-input-3 {
		position: static;
		width: 100%;
		height: 40px;
		margin-right: -25px;
		margin-bottom: 0px;
		border-style: none;
		border-radius: 50px 10px 10px 50px;
		opacity: 1;
	}

	.search-button-4 {
		margin-bottom: 0px;
		margin-left: 0px;
		background-color: #ca4949;
	}

	.search-button-4:hover {
		background-color: #333;
	}

	.menu-button {
		position: relative;
		top: 7px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		margin-top: 0px;
	}

	.menu-button.w--open {
		position: relative;
		top: 7px;
		display: block;
		background-color: transparent;
		color: #196ab5;
	}

	.mobile-nav-link {
		display: block;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		font-family: 'Open Sans', sans-serif;
		color: #fff;
		font-size: 15px;
		line-height: 20px;
		font-weight: 600;
	}

	.mobile-nav-link:hover {
		padding-right: 0px;
		padding-left: 0px;
		color: #fff;
	}

	.div-block-7 {
		width: 100%;
		height: 200px;
		background-color: #fff;
	}

	.mobile-icon-links {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		margin-top: -2px;
		padding: 60px 12%;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.link-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-decoration: none;
	}

	.icon-circle {
		width: 100px;
		height: 100px;
		border-radius: 100%;
		background-color: #ca4949;
		background-image: url('/20181016082423/assets/images/pay-bill.svg');
		background-position: 50% 50%;
		background-size: 60px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.icon-circle:hover {
		background-color: #196ab5;
	}

	.icon-circle-2 {
		width: 100px;
		height: 100px;
		border-radius: 100%;
		background-color: #ca4949;
		background-image: url('/20181016082423/assets/images/clock.svg');
		background-position: 50% 50%;
		background-size: 60px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.icon-circle-2:hover {
		background-color: #196ab5;
	}

	.icon-circle-3 {
		width: 100px;
		height: 100px;
		border-radius: 100%;
		background-color: #ca4949;
		background-image: url('/20181016082423/assets/images/calendar.svg');
		background-position: 50% 50%;
		background-size: 50px;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.icon-circle-3:hover {
		background-color: #196ab5;
	}

	.icon-text {
		margin-top: 10px;
		font-family: 'Open Sans', sans-serif;
		color: #ca4949;
		font-size: 20px;
		line-height: 25px;
		font-weight: 600;
		text-align: center;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.left-nav-col {
		display: none;
	}

	.inside-content {
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 5%;
		padding-left: 5%;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.primary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.secondary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.footer-text-inside {
		text-align: center;
	}

	.footer-copyright-inside {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.phone-link {
		color: #fff;
		text-decoration: none;
	}
}

@media screen and (max-width: 767px) {
	.nav-section {
		height: auto;
	}

	.nav-menu {
		margin-top: 104px;
	}

	.main-img {
		top: 0;
	}

	.slider {
		height: 500px;
	}

	.image-4 {
		max-width: 70%;
	}

	.side-icons {
		left: 1%;
		margin-left: 0%;
		padding-left: 0px;
	}

	.paybill {
		width: 60px;
		height: 60px;
		background-size: 35px;
	}

	.sideicon-text {
		font-size: 11px;
		line-height: 17px;
	}

	.side-link {
		width: 60px;
		height: 60px;
	}

	.calendar {
		background-size: 30px;
	}

	.sideicon-text-3 {
		font-size: 11px;
		line-height: 17px;
	}

	.sideicon-text-2 {
		font-size: 11px;
		line-height: 15px;
	}

	.left-alined-title {
		margin-bottom: 30px;
		font-size: 60px;
		line-height: 58px;
	}

	.content-boxes {
		padding-bottom: 0px;
	}

	.cb-content {
		padding-right: 5%;
		padding-left: 5%;
	}

	.centered-title {
		font-size: 60px;
		line-height: 58px;
	}

	.events-fb {
		padding: 60px 5%;
	}

	.alert-section {
		padding-right: 10%;
		padding-left: 5%;
	}

	.alert-text {
		max-width: none;
	}

	.close-alert {
		left: auto;
		top: 25%;
		right: 0%;
		bottom: 0%;
		width: 30px;
		height: 30px;
		margin-right: 20px;
	}

	.news-title {
		font-size: 60px;
		line-height: 58px;
	}

	.centered-title-white {
		font-size: 60px;
		line-height: 58px;
		font-weight: 700;
	}

	.footer-section {
		padding-right: 5%;
		padding-left: 5%;
	}

	.footer-column {
		margin-bottom: 20px;
	}

	.callout-container {
		padding-right: 5%;
		padding-left: 5%;
	}

	.column-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column-3 {
		overflow: hidden;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		float: none;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.footer-copyright {
		margin-bottom: -0.1rem;
		float: none;
	}

	.columns {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.div-block-5 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.mobile-icon-links {
		padding-right: 5%;
		padding-left: 5%;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.link-icon {
		width: auto;
	}

	.icon-text {
		text-align: center;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.main-content {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content-inner-wrap {
		padding-top: 0rem;
	}

	.footer-copyright-inside {
		margin-bottom: -0.1rem;
		float: none;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}
}

@media screen and (max-width: 479px) {
	.nav-section {
		height: auto;
	}

	.brand {
		width: 130px;
	}

	.nav-menu {
		margin-top: 98px;
	}

	.main-img {
		top: 0;
	}

	.slider {
		height: 400px;
	}

	.welcome-to {
		margin-bottom: 165px;
	}

	.image-4 {
		max-width: 79%;
	}

	.side-icons {
		position: fixed;
		left: 0%;
		top: 0%;
		right: auto;
		bottom: 0%;
		display: none;
		overflow: visible;
	}

	.left-alined-title {
		margin-bottom: 10px;
		font-size: 45px;
		line-height: 45px;
	}

	.main-paragraph {
		margin-top: 10px;
	}

	.cb-content {
		padding-right: 5%;
		padding-left: 5%;
	}

	.centered-title {
		font-size: 45px;
		line-height: 45px;
		text-align: center;
	}

	.cb-title {
		font-size: 30px;
		line-height: 35px;
	}

	.events-fb {
		padding-right: 5%;
		padding-left: 5%;
	}

	.alert-section {
		padding-right: 17%;
		padding-left: 5%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		font-size: 12px;
		text-align: center;
	}

	.alert-text {
		max-width: 300px;
		font-size: 15px;
		line-height: 20px;
		text-align: center;
	}

	.close-alert {
		margin-right: 5%;
	}

	.news-title {
		font-size: 45px;
		line-height: 45px;
	}

	.form-block {
		width: 90%;
		margin-bottom: 40px;
	}

	.centered-title-white {
		font-size: 45px;
		line-height: 45px;
	}

	.footer-section {
		padding-right: 5%;
		padding-left: 5%;
	}

	.callout-container {
		padding-right: 5%;
		padding-left: 5%;
	}

	.cool-button {
		font-size: 12px;
		line-height: 17px;
	}

	.cb-button {
		font-size: 12px;
		line-height: 17px;
	}

	.form-buttons {
		font-size: 12px;
		line-height: 17px;
	}

	.column-2 {
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.footer-signoff-link-wrap {
		text-align: center;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.mobile-icon-links {
		padding-top: 60px;
		padding-bottom: 60px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.icon-circle {
		width: 60px;
		height: 60px;
		background-size: 40px;
	}

	.icon-circle-2 {
		width: 60px;
		height: 60px;
		background-size: 40px;
	}

	.icon-circle-3 {
		width: 60px;
		height: 60px;
		background-size: 30px;
	}

	.icon-text {
		font-size: 15px;
		line-height: 20px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.primary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.secondary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.footer-copyright-inside {
		margin-bottom: 0.5rem;
		text-align: center;
	}
}

/***************** Webflow Styles *******************/
/****************************************************/

/****************** Tablesaw ***********************/
/****************************************************/
table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}


@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.page-wrap {
	overflow: hidden;
}

.nav-section-container {
	position: fixed;
	z-index: 10000;
	width: 100%;
	top: 0;
}

.nav-section-container.with-alert + .mm-page .inside-content {
	margin-top: 188px;
}

.nav-section-container.with-alert + .mm-page .main-img,
.nav-section-container + .mm-page .main-img {
	margin-top: 128px;
}

.nav-section-container + .mm-page .inside-content {
	margin-top: 123px;
}


@media (max-width: 991px) {
	.search-button-desktop,
	.search-desktop {
		display: none;
	}

	.nav-section-container.with-alert + .mm-page .inside-content {
		margin-top: 170px;
	}

	.nav-section-container + .mm-page .inside-content {
		margin-top: 100px;
	}

	.nav-section-container + .mm-page .main-img {
		margin-top: 105px;
	}

	.nav-section-container.with-alert + .mm-page .main-img {
		margin-top: 109px;
	}

	.navbar {
		padding: 4px 0px 9px 0px;
	}
}

@media (max-width: 767px) {
	.nav-section-container.with-alert + .mm-page .inside-content {
		margin-top: 164px;
	}

	.nav-section-container + .mm-page .inside-content {
		margin-top: 94px;
	}

	.nav-section-container + .mm-page .main-img {
		margin-top: 99px;
	}

	.nav-section-container.with-alert + .mm-page .main-img {
		margin-top: 109px;
	}
}

@media (max-width: 479px) {
	.navbar {
		padding: 4px 0px 10px 0px;
	}

	.nav-section-container.with-alert + .mm-page .inside-content {
		margin-top: 158px;
	}

	.nav-section-container + .mm-page .inside-content {
		margin-top: 88px;
	}

	.nav-section-container + .mm-page .main-img {
		margin-top: 93px;
	}

	.nav-section-container.with-alert + .mm-page .main-img {
		margin-top: 98px;
	}
}

/****************** Accesible menu ****************/
/*************************************************/
.accesible-menu {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: flex;
}

.accesible-menu li.nav-link-wrapper .nav-link.active-item {
	color: #ca4949;
}

.accesible-menu li.nav-link-wrapper {
	margin-left: 0px;
	padding: 0px;
	position: relative;
}

.accesible-menu li.nav-link-wrapper:after {
	content: "";
	width: 0;
	display: block;
	height: 2px;
	margin-bottom: 15px;
	background-color: #ca4949;
	opacity: 0;
	color: #ca4949;
	transition: width 200ms ease 0s, opacity 200ms ease 0s, height 200ms ease 0s;
}

.accesible-menu li.nav-link-wrapper:hover:after {
	opacity: 1;
	display: block;
	height: 2px;
	width: 50%;
}

.accesible-menu li.nav-link-wrapper .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	right: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-menu li.nav-link-wrapper .container-wrapper ul {
	display: none;
	padding: 10px;
	background-color: #196ab5;
	margin: 0px;
	list-style-type: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	min-width: 200px;
}

.accesible-menu li.nav-link-wrapper .container-wrapper ul a {
	width: 100%;
	padding: 10px 20px;
	border-radius: 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	line-height: 19px;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
}

.accesible-menu li.nav-link-wrapper .container-wrapper ul a:hover,
.accesible-menu li.nav-link-wrapper .container-wrapper ul a.w--current,
.accesible-menu li.nav-link-wrapper .container-wrapper ul a.active {
	background-color: hsla(0, 0%, 100%, 0.14);
	color: #fff;
	font-weight: 400;
}

.accesible-menu li.nav-link-wrapper.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-menu li.nav-link-wrapper.open .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 1005px) {
	.accesible-menu li a.nav-link {
		padding-left: 18px;
		padding-right: 18px;
	}
}

/****************** Mobile navigation ****************/
/*****************************************************/
.mobile-menu-button {
	position: relative;
	float: right;
	padding: 18px;
	font-size: 24px;
	cursor: pointer;
}

.mobile-navigation-menu {
	background-color: #196ab5;
}

.mobile-navigation-menu li a.nav-link {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 15px;
	line-height: 20px;
	width: auto;
	font-weight: 600;
}

.mobile-navigation-menu li:hover a.nav-link,
.mobile-navigation-menu li.active a.nav-link {
	background-color: rgba(255, 255, 255, 0.4);
}

.mm-listitem:after {
	border: 0px;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #fff;
}

.mm-navbar {
	height: 55px;
	text-align: left;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: 5px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
	top: 15px;
	left: 18px;
}

.mm-navbar__title {
	padding-top: 18px;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background: transparent;
}

.mobile-menu-button {
	display: none;
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-menu-button {
		display: block;
	}

	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.search-3 {
		margin-top: 10px;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 15px;
	}
}

/********************** Homepage ********************/
/****************************************************/
.main-paragraph p,
.main-paragraph li,
.main-paragraph a,
.main-paragraph span,
.paragraph-copy p,
.paragraph-copy li,
.paragraph-copy a,
.paragraph-copy a,
.paragraph-copy span,
.paragraph-2 p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
}

.paragraph-copy p:last-child,
.main-paragraph p:last-child,
.paragraph-2 p:last-child {
	margin-bottom: 0px;
}

.centered-title a,
.centered-title a:hover,
.left-alined-title a,
.left-alined-title a:hover,
.news-title a,
.news-title a:hover,
.centered-title-white a,
.centered-title-white a:hover,
.cb-title a,
.cb-title a:hover {
	border-bottom: 1px solid transparent;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

.contact-form *,
.contact-form p,
.contact-form li,
.contact-form a,
.contact-form a,
.contact-form span {
	color: #fff;
}

@media (min-width: 992px) {
	.callout {
		margin-top: -226px;
	}
}

/******************** Slideshow *********************/
/****************************************************/
.slider-caption-container {
	position: absolute;
	bottom: 70px;
	width: auto;
	background: #fff;
	padding: 15px;
	z-index: 60000;
}

@media (max-width: 991px) {
	.slider-caption-container {
		bottom: 130px;
	}
}

@media (max-width: 767px) {
	.slider-caption-container {
		bottom: 160px;
	}
}

@media (max-width: 479px) {
	.slider-caption-container {
		bottom: 160px;
	}
}

/******************** Inside page ********************/
/*****************************************************/
img {
	height: auto;
	max-width: 100%;
}

.right-nav .contentbox_item_image img {
	max-width: none;
}

.left-navigation-list ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child a {
	opacity: 1;
	color: #196ab5;
	font-weight: 700;
}

.image-12 {
	display: block;
}

.left-nav-col {
	flex-grow: 1;
}

.left-nav-link-3.active {
	color: #196ab5;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-nav {
	width: 21%;
	flex: 0 0 auto;
}

.inside-content.both-columns .right-nav {
	max-width: 245px;
}

.inside-content.only-right .right-nav {
	max-width: 300px;
}

.right-nav .box {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-nav .box h4,
.right-nav .box h3 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	color: #196ab5;
	font-style: normal;
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
}

.right-nav .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-nav.box .contentbox_item_image {
	width: 24px;
}

.right-nav .box.documents td:first-child {
	padding-right: 7px;
}

.right-nav .box.tools td:first-child img {
	margin-right: 5px;
}

.right-nav .box a {
	border-style: solid solid none;
	border-width: 1px;
	border-color: transparent;
	color: #196ab5;
	font-size: 1rem;
	text-decoration: none;
}

.right-nav .box a:hover {
	border-bottom: 0px none rgba(156, 203, 60, 0);
	color: #ca4949;
}

@media (max-width: 991px) {
	.right-nav {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid #ccc;
		width: 100%;
		max-width: none;
	}

	.right-nav .box {
		margin-left: 0em;
		margin-right: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-content.both-columns .right-nav {
		max-width: 100%;
	}

	.inside-content.only-right .right-nav {
		max-width: 100%;
	}
}

/********************** Footer ***********************/
/*****************************************************/
.wheelchair-icon {
	width: 100px;
	margin-left: -10px;
}

.phone-desktop {
	display: inline;
}

.phone-mobile {
	display: none;
}

.phone-mobile a,
.phone-mobile a:hover {
	border-bottom: 1px solid transparent;
	text-decoration: none;
	color: #333;
}

.footer-list {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.footer-list li {
	margin: 0px;
	padding: 0px;
}

#GRIPFooterLogo {
	margin-top: 1rem;
	margin-bottom: 1rem;
	line-height: 11px;
	padding-top: 0px !important;
}

.footer-link.active {
	color: #ca4949;
}

.inside-footer {
	background-color: #196ab5;
}

.inside-footer .footer-heading {
	font-family: 'Amatic SC', sans-serif;
	color: #fff;
	font-size: 40px;
	line-height: 45px;
}

.inside-footer .footer-text {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
}

.inside-footer .footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 0.9rem;
}

.inside-footer .footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.inside-footer .footer-link {
	margin-bottom: 5px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-decoration: none;
}

.inside-footer .link {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-weight: 600;
	text-decoration: none;
}

.inside-footer .footer-link:hover {
	opacity: 0.53;
	color: #fff;
}

.inside-footer .phone-mobile a,
.inside-footer .phone-mobile a:hover {
	color: #fff;
	text-decoration: none;
}

.inside-footer .footer-signoff-link:hover {
	opacity: 0.53;
	color: #fff;
	font-weight: 400;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline;
	}

	.footer-list li {
		text-align: center;
	}

	.wheelchair-icon {
		margin-left: 0px;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
h1 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 50px;
	line-height: 55px;
	font-weight: 600;
	text-transform: none;
}

h2 {
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-weight: 600;
	text-transform: none;
}

h3 {
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-weight: 600;
	text-transform: none;
}

h4 {
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-weight: 600;
	text-transform: none;
}

h5 {
	text-transform: none;
}

h6 {
	text-transform: none;
}

p {
	color: #333;
	font-size: 16px;
	line-height: 23px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #196ab5;
	text-decoration: underline;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #ca4949;
	text-decoration: underline;
}

a {
	color: #196ab5;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

a:hover {
	color: #ca4949;
}

blockquote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-color: #196ab5;
	border-top-right-radius: 0px;
	background-color: hsla(0, 0%, 72.9%, 0.18);
	color: #333;
	font-size: 1.35rem;
	line-height: 2rem;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	color: #414141;
	margin-top: 0;
}

/* buttons */
.button,
.primary,
button[type="submit"],
.cms_form_button.primary,
.form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.button {
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(202, 73, 73, 0.32);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	padding: 10px 20px;
	margin-bottom: 5px;
	text-align: center;
}

.button:hover,
.primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
.form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.button:hover {
	background-color: #ca4949;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.secondary,
button[type="submit"].secondary,
.cms_form_button.secondary,
.form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(25, 106, 181, 0.55);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	margin-bottom: 5px;
	letter-spacing: 0px;
	text-decoration: none;
	cursor: pointer;
	padding: 10px 20px;
	text-align: center;
}

.secondary:hover,
button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
.form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	border-color: rgba(25, 106, 181, 0.55);
	background-color: #196ab5;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.tertiary,
button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	min-width: 60px;
	padding: 10px 20px;
	border: 1px solid #c4ccd1;
	border-radius: 0px;
	background-color: transparent;
	color: #585b5d;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-transform: none;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	margin-bottom: 5px;
}

.tertiary:hover,
button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #f1f1f1;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: rgba(17, 185, 30, 0.25);
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #000000;
	font-weight: 500;
}

#message.error,
#polls .session_message.error {
	background-color: rgba(253, 54, 54, 0.15);
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #000000;
}

#message.success *,
#message.error *,
#polls .session_message.success p,
#polls .session_message.error p {
	color: #000000;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid #2e2e2e;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #196ab5;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #65a5e0;
	color: #ffffff;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-content input[type="text"],
.inside-content input[type="tel"],
.inside-content input[type="password"],
.inside-content input[type="email"],
.inside-content input[type="search"],
.inside-content select,
.inside-content textarea {
	max-width: 100%;
	padding: 0.39rem 0.75rem;
	border: 1px solid #746f6f;
	border-radius: 0px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	margin-bottom: 10px;
}

.inside-content input[type="search"] {
	margin-bottom: 0px;
}

.inside-content select {
	padding: 0.45rem 0.75rem;
}

.inside-content textarea {
	resize: vertical;
}

@media (max-width: 991px) {
	.inside-content input[type="text"],
	.inside-content input[type="tel"],
	.inside-content input[type="password"],
	.inside-content input[type="email"],
	.inside-content input[type="search"],
	.inside-content select,
	.inside-content textarea {
		width: 100%;
	}

	.inside-content input[type="search"] {
		margin-bottom: 10px;
	}
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(25, 106, 181, 0.55);
	border: 0px;
	color: rgba(25, 106, 181, 0.55);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
	color: #333 !important;
}

.pagination-wrapper span {
	color: #333 !important;
	font-size: inherit;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0px;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	padding: 0.5rem 1.25rem;
}


/************ Content Modules ************/
/*****************************************/
/* alerts */
#alerts_module.list .item_divider,
#alerts_module .item_divider {
	border-top: 1px solid rgba(25, 106, 181, 0.55);
	margin: 1.5rem 0px 1.3rem 0px;
}

#alerts_module.list .title h2 {
	margin-top: 0px;
}

#alerts_module_subscribe_form.form .item_divider {
	border-top: 1px solid rgba(25, 106, 181, 0.55) !important;
	margin-bottom: 0 !important;
}

#alerts_module_subscribe_form .form_container h3 {
	padding-top: 0.75rem;
}

#alerts_module.form .field {
	padding-bottom: 15px;
}

#alerts_module.form .field small {
	display: block;
}

@media (max-width: 991px) {
	#alerts_module_subscribe_form.form .form_container {
		width: 100%;
	}

	#alerts_module.form .form_container .form_item .field,
	#alerts_module.form .form_container .form_item .field input[type="text"] {
		width: 100% !important;
		display: block;
		text-align: left;
		background: none;
	}

	#alerts_module.form .form_container .form_item .field {
		padding-left: 0px;
	}

	#alerts_module.form .form_container .form_item .label,
	#alerts_module.form .form_container .form_item.reqiuired .label {
		display: inline-block;
		text-align: left;
		width: auto;
	}

	#alerts_module.form .form_container .form_item.required .label {
		background: url("/core/public/shared/assets/images/required.gif") no-repeat right 11px;
		padding-right: 15px;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(25, 106, 181, 0.55);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

.fc-day-grid-event .fc-time {
	font-weight: normal;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(25, 106, 181, 0.55);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	margin-bottom: 5px;
	font-size: 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(25, 106, 181, 0.55);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: bold;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.75rem 1.25rem;
	margin-top: 10px;
}

#jobs_module.cms_form .cms_divider {
	display: none;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(25, 106, 181, 0.55);
}

#news_module.cms_entity .image {
	max-width: 250px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* resources */

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(25, 106, 181, 0.55);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

/*form with payment*/
#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
}

#submissionforms_module.cms_form .cms_field.currency span,
.responsive #submissionforms_module.cms_form .cms_field.currency span {
	margin-left: 20px !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*confirmation page*/
.module-forms.confirmation .step-title {
	margin-top: 1rem;
	color: #002147;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

.module-forms.confirmation .label-cell {
	font-weight: normal;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 1.527rem;
	margin-top: 0px;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}

	#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 40%;
		left: 5px;
	}
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(25, 106, 181, 0.55);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.module-search input[type="search"] {
	margin-bottom: 0px;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/************ Content Boxes ************/
/*****************************************/
/* submsision forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	padding: 8px 12px;
	margin-bottom: 20px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #746f6f;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

.contact-form #submissionforms_module.homepage_contentbox table {
	width: 100%;
}

.contact-form #submissionforms_module.homepage_contentbox .cms_hint,
.contact-form #submissionforms_module.homepage_contentbox .cms_date_hint {
	color: #fff !important;
}

.contact-form #submissionforms_module.homepage_contentbox .cms_label {
	padding-bottom: 5px;
}

.contact-form #submissionforms_module.homepage_contentbox input[type="text"],
.contact-form #submissionforms_module.homepage_contentbox input[type="tel"],
.contact-form #submissionforms_module.homepage_contentbox input[type="password"],
.contact-form #submissionforms_module.homepage_contentbox input[type="email"],
.contact-form #submissionforms_module.homepage_contentbox input[type="search"],
.contact-form #submissionforms_module.homepage_contentbox select,
.contact-form #submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	padding: 8px 12px;
	margin-bottom: 20px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #746f6f;
}

.contact-form #submissionforms_module.homepage_contentbox table label {
	color: #fff;
	font-size: 21px;
	line-height: 26px;
	font-weight: 600;
}

.contact-form #submissionforms_module.homepage_contentbox .cms_buttons {
	padding: 5px 0 5px 0;
	text-align: center;
}

/* news */
.news-date {
	margin-bottom: 10px;
	margin-left: 40px;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 16px;
	font-weight: 600;
}

/* events */
#events_module.homepage_contentbox .cb {
	padding-top: 40px;
}

/* alerts */
#alerts_module.homepage_contentbox {
	text-align: left;
}

#alerts_module.homepage_contentbox ul {
	list-style-type: none;
}

#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	color: #333;
	font-size: 0.9rem;
}

.contact-form #alerts_module.homepage_contentbox.list_alerts .alerts a:link,
.contact-form #alerts_module.homepage_contentbox.list_alerts .alerts a:active,
.contact-form #alerts_module.homepage_contentbox.list_alerts .alerts a:visited,
.contact-form #alerts_module.homepage_contentbox.list_alerts .date {
	color: #fff !important;
}

/*************** Alert Region **************/
/*****************************************/
.alert-wrap {
	display: none;
}

.alert-wrap .alert-section * {
	color: #fff;
}

.alert-wrap .alert-section p {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
}

.alert-wrap .alert-section .cms-website-content-region p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
	.alert-wrap .alert-section .cms-website-content-region {
		max-width: 500px;
	}

	.alert-wrap .alert-section p {
		font-size: 20px;
		line-height: 25px;
	}
}


@media screen and (max-width: 767px) {
	.alert-wrap .alert-section .cms-website-content-region {
		max-width: none;
	}
}

@media screen and (max-width: 479px) {
	.alert-wrap .alert-section .cms-website-content-region {
		max-width: none;
		max-width: 300px;
		text-align: center;
	}

	.alert-wrap .alert-section p {
		font-size: 15px;
		line-height: 20px;
	}
}
